
:root {
  --themeYellow: #FFC321;
}
.navbar .navbar-brand {
  max-width: 180px;
}
.navbar .navbar-brand img {
  max-width: 100%;
  height: auto;
}
.navbar .nav-link {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.navbar .nav-link:hover {
  color: var(--themeYellow);
}
.themeBtn {
  padding: 7px 12px;
  min-width: 134px;
  text-align: center;
  background-color: #000;
  border-radius: 30px;
  font-size: 14px;
  color: white;
  border: 2px solid #000;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
}
.themeBtn:hover {
  color: #000;
  background-color: #fff;
}
.ai-center {
  align-items: center;
}
.sec1 {
  padding: 50px 0;
}
.weCanHelp img {
  filter: invert(1) brightness(0) ;
}
.weCanHelp {
  padding: 3px 18px;
  background-color: var(--themeYellow);
  border-radius: 22px;
  font-weight: 500;
  display: inline-block;
  border: 2px solid var(--themeYellow);
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  text-decoration: none;
}
.weCanHelp:hover {
  background-color: #fff;
  color: #000;
}
.sec1 h1 {
  color: #312F31;
  /* font-family: Archivo; */
  font-size: 81px;
  font-weight: 700;
  max-width: 750px;
  margin-bottom: 50px;
}
.sec1 h1 span {
  background: var(--Linear, linear-gradient(90deg, #DA91E1 0%, #FCC355 93.28%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.sec1 .para {
  max-width: 380px;
  margin: 30px 0;
  margin-top: 60px;
}
.sec1 .getStartMain .themeBtn {
  text-transform: uppercase;
  padding: 7px;
  margin-top: 50px;
}
.sec1 .getStartMain .themeBtn span {
  display: inline-block;
  margin-right: 20px;
  margin-left: 15px;
}
.sec1 .getStartMain .learnBtn{
  /* padding: 7px 12px; */
  min-width: 134px;
  text-align: center;
  background-color: #fff;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-left: 5px;
  border: none;
}
.sec1 .getStartMain .learnBtn:hover { 
  color: var(--themeYellow);
}
.para {
  color: #000;
  font-size: 16px;
}
.homePara {
  color: #5C5C5C;
  font-size: 16px;
  font-weight: 500;
}
.homeHeading {
  color: #141413;
  font-size: 50px;
  font-weight: bold;
  margin-bottom: 20px;
}
.gradColor {
  background: var(--Linear, linear-gradient(90deg, #DA91E1 0%, #FCC355 93.28%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.al-fe {
  align-items: flex-end;
}
.sec1 .line {
  position: absolute;
  left: 0;
  right: 0;
  z-index: -1;
  opacity: 0.2;
  margin-top: -60px;
}
.sec1 .line img {
  width: 100%;
}
.sec2 {
  padding: 50px 0;
}
.sec2 .sec2M {
  padding: 20px 40px;
  background-color: #312F31;
  border-radius: 70px;
  color: #fff;
  text-align: center;
}
.sec2 .sec2M .val {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 0px;
}
.sec2 .sec2M .title {
  font-size: 14px;
  text-transform: uppercase;
  opacity: 0.8;
}
.sec3 {
  padding: 50px 0;
  padding-top: 100px;
}
.sec3 .content {
  max-width: 525px;
  margin-left: auto;
}
.sec3 .content .title {
  color: #FFBF00;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
}
.sec3 .content .title span {
  display: inline-block;
  height: 2px;
  width: 40px;
  background: #FFBF00;
  margin-right: 10px;
  margin-bottom: 6px;
}
.sec3 .imgDiv.up {
  position: relative;
  top: -50px;
}
.themeBtn2 {
  font-size: 18px;
  padding: 12px 35px;
}
.sec4 {
  padding: 50px 0;
}
.sec7 {
  padding: 50px 0;
}
.sec7 .homeHeading {
  font-size: 65px;
  line-height: 1.2;
  max-width: 750px;
}
.sec7 .weCanHelp {
  text-transform: capitalize;
}
.serviceSec {
  padding: 50px 0;
}
.serviceSec .homePara {
  font-size: 22px;
  margin-bottom: 30px;
}
.serviceSec .sCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
  border: 1.032px solid #DDD;
  border-radius: 35px;
  font-size: 23px; 
  color: #444;
  margin-bottom: 30px;
}
.serviceSec .sCard:hover {
  background: #101010;
  color: #fff;
}
.serviceSec .sCard:hover svg {
  filter: invert(0) brightness(1);

}
.serviceSec .sCard .no {
  font-weight: 700;
  margin-right: 15px;
  /* font-family: Archivo; */
}
.serviceSec .sCard .title {
  text-transform: capitalize;
  font-weight: 500;
}
.serviceSec .sCard svg {
  filter: invert(1) brightness(0);
}
.serviceSec .howCard {
  background:#312F31;
  padding: 30px 25px;
  color: #fff;
  border-radius: 20px;
  margin-bottom: 25px;
}
.serviceSec .howCard p {
  font-size: 18px;
  margin-bottom: 70px;
}
.serviceSec .howCard .seeHow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 31px;
  font-weight: 600;
  line-height: 1;
}
.serviceSec .howCard .seeHow .arrow {
  background: #FFBF00;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.serviceSec .howCard .seeHow .arrow img {
  width: 16px;
}
.serviceSec .meetCard {
  background: #FFBF00;
  padding: 30px 25px;
  color: #222;
  border-radius: 20px;
}
.serviceSec .meetCard p {
  font-size: 18px;
  margin-bottom: 60px;
}
.serviceSec .meetCard .seeHow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 31px;
  font-weight: 600;
  line-height: 1;
}
.serviceSec .meetCard .seeHow .arrow {
  background: #FFF;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}
.serviceSec .meetCard .seeHow .arrow img {
  filter: invert(1) brightness(0);
  width: 16px;
}
.sec5 {
  padding: 50px 0;
}
.sec5 .card1 {
  padding: 40px;
  padding-top: 60px;
  color: #fff;
  background: url(./images/experties1.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.sec5 .card1 .para {
  color: rgba(255, 255, 255, 0.63);
  margin: 20px 0;
}
.sec5 .card1.card3 .para {
  color: #312F31;
}
.sec5 .card2 {
  background: url(./images/experties2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}
.sec5 .card3 {
  background: url(./images/experties3.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  
}
.sec5 .card1.card3 .head {
  color: #312F31;
}
.sec5 .card1 .head {
  color: #FFF;
  /* font-family: Poppins; */
  font-size: 27px;
  font-weight: bold;
}
.sec5 .card1.card3 .title {
  color: #312F31;
}
.sec5 .card1 .title {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 30px;
}
.sec5 .card1 .title span {
  display: inline-block;
  height: 2px;
  width: 20px;
  background: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
}
.sec5 .card1 .readMore {
  color: #FFBF00;
  position: relative;
  text-decoration: none;
}
.sec5 .card1.card3 .readMore {
  color: #312F31;
}
.sec5 .card1 .readMore img {
  margin-left: 6px;
}
.sec5 .card1.card3 .readMore img{
  filter: invert(1) brightness(0);
}
.sec5 .card1 .readMore::before {
  position: absolute;
  content: '';
  background: #FFBF00;
  height: 2px;
  width: 100%;
  bottom: -3px;
}
.sec5 .card1.card3 .readMore::before {
  position: absolute;
  content: '';
  background: #312F31;
  height: 2px;
  width: 100%;
  bottom: -3px;
}
.testimonialSec {
  padding: 50px 0;
}
.testimonialSec .title {
  color: #FFBF00;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}
.testimonialSec .title span {
  display: inline-block;
  height: 2px;
  width: 40px;
  background: #FFBF00;
  margin-right: 10px;
  margin-bottom: 6px;
}
.testimonialSec .testCard {
  padding: 40px 30px;
  background-color: #312F31;
}
.testimonialSec .testCard .row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.testimonialSec .testCard .row1 .profM {
  display: flex;
  align-items: center;
}
.testimonialSec .testCard .row1 .profM .name {
  color: #fff;
  font-size: 17px;
  font-weight: bold;
}
.testimonialSec .testCard .row1 .profM .desig {
  color: #fff;
  font-size: 14px;
}
.testimonialSec .testCard .row1 .profM .imgDiv {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
}
.testimonialSec .testCard .row1 .profM .imgDiv img {
  width: 100%;
  min-height: 100%;
}
.testimonialSec .testCard .row1 .rate {
  color: #fff;
  font-size: 22px;
}
.testimonialSec .testCard .para {
  color: #fff;
  font-size: 15px;
}
footer {
  background-color: #312F31;
  padding-top: 60px;
  color: #fff;
}
footer .innerFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  /* border-bottom: 1px solid #ADB1C2; */
}

footer .socialMain {
  max-width: 270px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
footer .socialMain a {
  display: inline-block;
  margin-left: 3px;
  margin-bottom: 5px;
  transition: all 0.3s;
}

footer .socialMain a:hover {
  transform: scale(1.2);
  transition: all 0.3s;
}

footer .socialMain a img {
  width: 30px;
}

footer img {
  width: 130px;
}

footer .copyRight {
  padding: 20px;
  color: #fff;
  text-align: center;
  border-top: 1px solid rgba(255, 255, 255, 0.14);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

footer .quickLinks{
  /* font-family: campton; */
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  text-transform: capitalize;

}
footer .desc{
  color: #fff;
}
footer ul{
  list-style: none;
  padding-left: 0 !important;
}
footer ul li{
  padding: 2px 0;
}
footer a{
  color: #fff ;
  cursor: pointer;
  text-decoration: none;
}
footer a:hover{
  color: #FFBF00 ;
  opacity: 0.8;
  text-decoration: none;
}
footer .subsMain button:hover {
  background: #fff;
  color: #FFBF00;
}
footer .subsMain button {
  background: #FFBF00;
  border: none;
  /* font-weight: bold; */
  padding: 7px 15px;
  color: #fff;
  border-radius: 20px;
}
footer .subsMain input{
  border: none;
  width: 100%;
  background: transparent;
  outline: none;
  border: none;
  padding-left: 10px;
  color: #fff;
}
footer .subsMain{
  background: transparent;
  max-width: 350px;
  display: flex;
  margin-bottom: 20px;
  border-radius: 20px;
  overflow: hidden;
  border: 1.5px solid rgba(255, 255, 255, 0.11);
  padding: 5px;
}
.navbar-toggler img {
  width: 45px;
}
.termsPage {
  padding: 40px 0;
}

@media screen and (min-width:1200px) and (max-width:1400px) {
  .serviceSec .howCard p {
    margin-bottom: 60px;
  }
  .serviceSec .meetCard p {
    margin-bottom: 60px;
  }
  .serviceSec .howCard .seeHow {
    font-size: 28px;
  }
  .serviceSec .meetCard .seeHow {
    font-size: 28px;
  }
  .serviceSec .homePara {
    font-size: 18px;
    margin-bottom: 40px;
  }
  .sec5 .card1 .head {
    font-size: 26px;
  }
  .sec1 h1 {
    font-size: 77px;
  }
}
@media screen and (max-width:992px) {
  .sec4 .content {
    margin-bottom: 20px;
  }
  .serviceSec .sCard:last-child {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width:768px) {
  .sec1 .getStartMain .themeBtn {
    margin-bottom: 20px;
  }
}
@media screen and (max-width:576px) {
  .sec1 h1 {
    font-size: 40px;
  }
  .sec1 .line {
    margin-top: -25px;
  }
  .homeHeading {
    font-size: 35px;
  }
  .serviceSec .sCard .title {
    font-size: 18px;
  }
  .serviceSec .sCard .no{
    font-size: 18px;
  }
  .serviceSec .howCard {
    margin-top: 20px;
  }
  .sec7 .homeHeading{
    font-size: 35px;
  }
}